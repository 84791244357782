export default class FlutterBridge {
  constructor() {
    this.bridge = window.flutter_inappwebview;
    this.ready = false;

    // DOM Listeners
    window.addEventListener("flutterInAppWebViewPlatformReady", function () {
      this.bridge = window.flutter_inappwebview;
      this.ready = true;

      console.debug("[FlutterBridge] Ready", this.bridge);
    });
  }

  /**
   * Send a message to Flutter
   * @param {String} handlerName The name of the targeted Flutter handler
   * @param {String} message The message sent to Flutter
   * @param {Object} options An object of options to compose the message
   * @param {Boolean} options.json Should the message be wrapped in JSON.stringify?
   */
  async sendMessage(handlerName, message, options = { json: true }) {
    console.debug("[FlutterBridge] sending", handlerName, message, options);
    if (!this.bridge) {
      console.error("[FlutterBridge] There is no bridge available");
    }

    if (options.json) {
      message = JSON.stringify(message);
    }

    return this.bridge.callHandler(handlerName, message);
  }
}

(function () {
  if (window && window.flutter_inappwebview) {
    window.FlutterBridge = new FlutterBridge();
  }
})(window);
